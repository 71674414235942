<div class="footer text-base text-vis-reverse bg-vis-dark" *ngIf="showHideFooter">
  <div class="flex flex-wrap container !py-12">
    <!-- Logo -->
    <div class="footer__logo-container w-full xs:pl-v-spacing md:pl-0 lg:w-[40%] pb-v-spacing lg:pb-0 flex flex-col md:flex-row lg:flex-col justify-start md:justify-between lg:justify-start align-top">
      <a
        class="hover:text-ace-teal-500 pb-v-spacing md:pb-0"
        *ngFor="let footerLogo of footerLogos"
        [href]="footerLogo.logoLink"
        aria-label="Home"
      >
        <img
          width="171"
          height="44"
          *ngIf="footerLogo.logoImage.length > 0"
          [src]="footerLogo.logoImage[0].url"
          [alt]="footerLogo.logoImage[0].altText ? footerLogo.logoImage[0].altText : ''"
        />
        {{ footerLogo.logoImage.length === 0 ? stripLink(footerLogo.logoLink) : '' }}
      </a>
      <!-- Refer and Partner Buttons -->
      <div *ngIf="shareButtons" class="flex flex-col xs:flex-row footer-buttons mt-6 sm:mt-8 md:mt-0 lg:mt-12">
          <a *ngFor="let b of shareButtons; let last = last; trackBy: itemLabel"
            class="
              footer-button
              text-ace-teal-600
              hover:text-vis-reverse
              active:text-vis-reverse
              inline-flex
              cursor-pointer
              items-center
              justify-center
              py-3.5
              px-6
              w-full
              xs:w-fit
              border-[1.5px]
              border-solid
              border-ace-teal-600
              hover:bg-ace-teal-600
              active:border-ace-teal-700
              active:bg-ace-teal-700
              rounded-[100px]
              {{ !last ? 'mb-2 xs:mr-2 xs:mb-0' : '' }}"
            href="{{b.link}}"
            [attr.aria-labelledby]="requestService.getAriaLabelledby(b.label) + '-nav-label'"
          >
            <span class="label-primary" [innerHTML]="b.label"></span>
            <!-- Hidden Context -->
            <span [attr.id]="requestService.getAriaLabelledby(b.label) + '-nav-label'" class="sr-only">{{requestService.getCompleteTextContextForButton(b.link, b.label)}}</span>
          </a>
      </div>
    </div>
    <!-- General Links: Careers, Partnerships, Help Center, Contact, Our Blog -->
    <footer-link
      class="w-1/2 xs:w-1/3 md:mt-1 xs:pl-v-spacing pt-v-spacing md:pl-0 lg:pt-0 lg:px-v-spacing lg:mt-0 mb-v-spacing lg:w-1/6"
      [links]="generalLinks"
      footerLocation="general"
      alignClass="md:items-end md:justify-end"
      linkClass="w-full lg:w-full"
    ></footer-link>
    <div class="
    w-[60%] xs:w-1/3 md:mt-1 xs:pl-v-spacing pt-v-spacing md:pl-0 lg:pt-0 lg:px-v-spacing lg:mt-0 mb-v-spacing lg:w-3/12
   ">
    <footer-link
      [links]="generalLinks2"
      footerLocation="general"
      alignClass="md:items-end md:justify-end"
      linkClass="w-full lg:w-full"
    ></footer-link>
    <div class="mt-3">
      <button (click)="openPrivacyManagerModal()" class="self-end text-left text-sm">Do not sell or share my information</button>
    </div>
  </div>
    <!-- Social Links: Twitter, Facebook, Instagram, LinkedIn, YouTube -->
    <footer-link
    class="
    md:pl-0
    lg:mt-0
    w-full
    xs:w-1/3
    mx-0
    xs:pl-v-spacing
    pt-v-spacing
    md:pl-0
    md:mt-1
    lg:py-0 lg:px-v-spacing lg:w-1/6
    py-3
    md:px-v-spacing
  "
      [links]="socialLinks"
      footerLocation="social"
      alignClass="lg:items-start lg:justify-end w-full sm:w-3/4 md:w-full"
      linkClass=""
    ></footer-link>
    <!-- Copyright -->
    <div class="w-full flex justify-between">
      <div class="text-xs self-end xs:pl-v-spacing md:pl-0 lg:text-left footer__copyright py-1 w-full lg:w-1/2">
        <p>610 W. 5th Street, Ste. 605, Austin, TX 78701</p>
        <p>© {{ copyrightYear }} Aceable, Inc. All rights reserved.</p>
      </div>
    </div>
  </div>
</div>
<email-capture [data]="emailCaptureData"></email-capture>

<privacy-manager *ngIf="showPrivacyManagerModal" (hideModal)="showPrivacyManagerModal = false;"></privacy-manager>
