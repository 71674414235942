/**
 * # Logger Util
 *
 * Wraps console log to have consistent formatting for Coralogix logging
 * Should be used in place of console.log, console.error in SSR / Backend code
 * Can be used in frontend code with no issues
 *
 * 🎗️ Messages should always be succinct, non-dynamic, and non-redundent. Leverage tags and contextId for info. Add additional details if needed
 *
 *  Bad Example: log({ message: 'User Error 1234 logged in on 2024-01-01' });
 * Good Example: log({ severity: SEVERITY.ERROR, message: 'LOGIN', contextId: '1234', date: '2024-01-01', tags: ['USER'] });
 *
 * To help avoid accidental overload, you cannot send complex object details
 */

/**
 * Official list supported by Coralogix parser
 */
export const SEVERITY = {
  DEBUG: 'DEBUG',
  INFO: 'INFO',
  WARN: 'WARN',
  ERROR: 'ERROR',
} as const;

export type LogSeverity = keyof typeof SEVERITY;

export interface LogError extends Error {
  code?: string | number;
}

type Simple = string | number | boolean | Date | string | undefined;

export interface LogRequest {
  severity?: LogSeverity;
  message: string;
  code?: string | number;
  brandId?: string;
  stack?: string;
  contextId?: string | number;
  tags?: string[];
  [key: string]: Simple | Simple[] | Record<string, Simple>;
}

export interface ErrorRequest {
  severity?: LogSeverity;
  message?: string;
  error: LogError;
  contextId?: string;
  brandId?: string;
  tags?: string[];
  [key: string]: LogError | Simple | Simple[] | Record<string, Simple>;
}

function truncate(value, limit = 64): Simple {
  // Unset nullish properties to minimize logs
  if (value == null) {
    return undefined;
  }

  // Otherwise respect simple type or force a string and truncate
  if (typeof value === 'number' || typeof value === 'boolean') {
    return value;
  }

  return String(value).slice(0, limit);
}
/**
 * Centralized logging utility for the application
 *
 * @param {Object} args
 * @param message - Should be succint and static (no dynamic variables) for easy searching
 * @param code - Can be like 50001 or UTIL_ERROR string but should be unique to log() call
 */
export function log({
  severity = SEVERITY.INFO,
  message,
  code = 20000,
  contextId = undefined,
  brandId = undefined,
  stack = undefined,
  tags = [],
  ...details
}: LogRequest): void {
  if (!Object.values(SEVERITY).includes(severity)) {
    severity = SEVERITY.INFO;
  }

  const consoleMethod = severity.toLowerCase() as Lowercase<LogSeverity>;

  // Truncate details to 256 characters and return as new object
  const truncatedDetails = Object.entries(details).reduce((acc, [key, value]: [string, unknown]) => {
    if (typeof value !== 'object') {
      acc[key] = truncate(value, 256);
      return acc;
    }

    let stringValue: Simple[] | Record<string, Simple>;

    if (Array.isArray(value)) {
      stringValue = value.map((r) => truncate(r, 256)).filter(Boolean);
    } else {
      stringValue = Object.entries(value as Record<string, Simple>).reduce((obj, [k, v]) => {
        obj[k] = truncate(v, 256);
        return obj;
      }, {} as Record<string, Simple>);
    }

    acc[key] = stringValue;
    return acc;
  }, {} as Record<string, Simple | Simple[] | Record<string, Simple>>);

  // Package into a Coralogix JSON string with the appropriate properties
  let output = JSON.stringify({
    code: truncate(code),
    contextId: truncate(contextId),
    brandId: truncate(brandId, 3),
    message: truncate(message, 256),
    platform: 'VIS',
    severity: consoleMethod,
    stack: truncate(stack, 1024),
    tags: tags.map((t) => truncate(t, 64)).filter(Boolean),
    ...truncatedDetails,
  });

  // Flatten for Coralogix to handle multiline logs
  output = output.replace(/(\\r|\\n)+/g, ' ').replace(/ +/g, ' ');

  // eslint-disable-next-line no-console
  console[consoleMethod](output);
}

/**
 * Helper method to parse a standard Error into log() format
 * Note you can pass an error but force a different severity
 */
export function logError({
  error,
  message = undefined,
  severity = SEVERITY.ERROR,
  contextId,
  brandId,
  tags,
  ...details
}: ErrorRequest): void {
  log({
    severity,
    message: message ?? error.message,
    code: error.code ?? 50000,
    // If user passes a custom message, let Error.message be additional info
    ...(message ? { errorMessage: error.message } : {}),
    stack: error.stack,
    contextId,
    brandId,
    tags,
    ...details,
  });
}
