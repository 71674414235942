import { Location } from '@angular/common';
import type { ErrorHandler } from '@angular/core';
import { Injectable } from '@angular/core';
import { EnvService } from '@core-mkt/services/env/env.service';
import type { ConsoleData } from './error-handler';

import type { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import type { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import type { LogError } from '../../../shared/lib/logger';
import { logError, SEVERITY } from '../../../shared/lib/logger';

@Injectable({
  providedIn: 'root',
})
export class ServerErrorHandlerService implements ErrorHandler, HttpInterceptor {
  visionData: ConsoleData;

  constructor(private location: Location, private env: EnvService) {
    const path = this.location.path();
    const slug = path.substring(1, path.length - 1);

    this.visionData = {
      appParams: this.env.get.xgritApiConfig.baseParams,
      urlPath: path,
      craft: {
        slug: this.env.get.slugPrefix + slug.replace(/\//g, '--'),
        site: this.env.get.brandConfig.craftSite,
      },
    };
  }

  /**
   * This is an interceptor that handles HTTP errors.
   *
   * It intercepts the request, and if there is an error, it will throw a
   * `HttpErrorResponse`.
   *
   * @param request The request to intercept.
   * @param next The next `HttpHandler` in the chain.
   * @returns The `Observable` of the `HttpEvent`.
   */
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      }),
    );
  }

  /**
   * handleError catches any unhandled runtime error on the server side and formats it for Coralogix
   *
   * @param {object} error - Native error throwned by Angular
   */
  handleError(error: any): void {
    logError({
      error: error as LogError,
      severity: SEVERITY.ERROR,
      params: error.appParams as string[],
      urlPath: error.urlPath as string,
      craft: error.craft as string[],
      message: this.getStatusMessage(error.status),
      tags: ['ServerErrorHandlerService.handleError'],
    });
  }

  /**
   * get context based on status code. This is used to determine if the error is a runtime error or http error
   *
   * @param {number} status - status code
   */
  private getStatusMessage = (status) => {
    switch (status) {
      case undefined:
        return 'Runtime JS';
      case 0:
        // Check if url request exists or CORS error
        return 'Http Preflight';
      default:
        // Please look at the nativeErr property for more details
        return 'Http Native';
    }
  };
}
