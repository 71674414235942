import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import type { FormControl } from '@angular/forms';
import { FormBuilder, Validators } from '@angular/forms';
import type { SafeHtml } from '@angular/platform-browser';
import { ProductService } from '@core-mkt/services/product/product.service';
import type { XGritCompleteProduct } from '@core-mkt/services/xgrit-api/xgrit-product';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import { EnvService } from '@core-mkt/services/env/env.service';
import { IterableService } from '@core-mkt/services/iterable/iterable.service';
import { PricingService } from '@core-mkt/services/pricing/pricing.service';
import type { ComponentTheme, ThemeInterface } from '@core-mkt/services/theme-parser/theme-parser.service';
import { ThemeParserService } from '@core-mkt/services/theme-parser/theme-parser.service';
import { WysiwygRedactorService } from '@core-mkt/services/wysiwg-redactor/wysiwyg-redactor.service';
import { takeWhile } from 'rxjs';
import type { FormPayload } from './email-form';
import { EmailForm, UserType } from './email-form';

@Component({
  selector: 'email-form',
  templateUrl: './email-form.component.html',
  styleUrls: ['./email-form.component.scss'],
})
export class EmailFormComponent implements OnInit {
  @Input() data: EmailForm;
  ctaLayout = '';
  faTimesCircle = faTimesCircle;
  deListIds: number[];
  formBuilder: FormBuilder;
  formContent: SafeHtml;
  formErr = false;
  formSubmitted = false;
  formFields: { name: string; label: string; type: string; required: boolean }[];
  hasContent = false;
  reListIds: number[];
  signUpForm;
  theme: ThemeInterface;
  product: XGritCompleteProduct;
  postErr = false;
  emailRegEx =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  constructor(
    private redactor: WysiwygRedactorService,
    private themeParser: ThemeParserService,
    formBuilder: FormBuilder,
    private ps: ProductService,
    private iterable: IterableService,
    private env: EnvService,
    private PricingService: PricingService,
  ) {
    this.formBuilder = new FormBuilder();

    this.signUpForm = this.formBuilder.group({
      email: ['', [Validators.email]],
      name: ['', [Validators.pattern('^[a-zA-Z ]*$')]],
      phoneNumber: ['', [Validators.pattern('^[0-9]*$')]],
      parentEmail: ['', [Validators.email]],
    });
  }

  get emailFormWrapperClasses(): string {
    let wrapperClass = this.theme.container;
    if (this.emailImageExists) {
      wrapperClass += ' ' + this.imgAlign;
    }
    return wrapperClass;
  }

  get canSubmit(): boolean {
    if (this.data.showParentEmailField && this.data.formVertical === 'DE') {
      if (this.parentEmail.value !== '') {
        return this.canSubmitEmail && this.canSubmitParent;
      }
    }
    return this.canSubmitEmail;
  }

  get canSubmitEmail(): boolean {
    const emailRegEx = this.emailRegEx;
    return this.email.valid && emailRegEx.test(this.email.value);
  }

  get canSubmitParent(): boolean {
    const emailRegEx = this.emailRegEx;
    if (this.data.showParentEmailField && this.parentEmail.value !== '' && this.data.formVertical === 'DE') {
      return this.parentEmail.valid && emailRegEx.test(this.parentEmail.value);
    } else if (this.parentEmail.value == '') {
      return true;
    }
  }

  get ctaLabel(): string {
    if (this.data.ctaLabel && this.data.ctaLabel.length > 0) {
      return this.data.ctaLabel;
    }
    if (this.data.formVertical === 'RE') {
      return 'Subscribe';
    }
    return 'Send';
  }

  get ctaType(): string {
    return this.data.ctaType === 'secondary' || this.data.ctaType === 'tetriary' ? this.data.ctaType : 'primary';
  }

  get idArray(): number[] {
    if (this.data.formVertical === 'RE') {
      return this.reListIds;
    }
    return this.deListIds;
  }

  get email(): FormControl {
    return this.signUpForm.get('email');
  }

  get phoneNumber(): FormControl {
    return this.signUpForm.get('phoneNumber');
  }

  get name(): FormControl {
    return this.signUpForm.get('name');
  }

  get parentEmail(): FormControl {
    return this.signUpForm.get('parentEmail');
  }

  get imgAlign(): string {
    return this.data.imageAlignment ? 'lg:' + this.data.imageAlignment : 'lg:flex-row mx-auto';
  }

  get payloadEmail(): string {
    if (this.data.formVertical !== 'RE' && this.data.showParentEmailField && this.parentEmail.value.length > 0) {
      return this.parentEmail.value;
    }
    return this.email.value;
  }

  get fullForm(): boolean {
    if (!this.data.formType) {
      return !this.data.formType;
    } else {
      const options = [
        this.data.showNameField,
        !this.data.hideEmailField,
        this.data.showParentEmailField,
        this.data.showPhoneNumberField,
      ];
      const trueCount = options.filter(Boolean).length;
      if (trueCount > 1) {
        return true;
      } else {
        return false;
      }
    }
  }

  get hideContentOnMobile(): boolean {
    return this.data.hideContentOnMobile;
  }

  get emailImageExists(): boolean {
    return !!(this.data.emailFormImage && this.data.emailFormImage.length > 0);
  }

  get imagePadding(): string {
    return this.imgAlign.includes('flex-row-reverse') ? 'lg:pl-10' : 'lg:pr-10';
  }

  get formClasses(): string {
    let classes = '';
    if (!this.fullForm) {
      classes = 'flex-col sm:flex-row md:ml-auto mt-3 md:mt-0';
    } else {
      classes = 'lg:mr-auto flex-wrap flex-col py-v-spacing sm:p-v-spacing m-auto pt-3';
      if (!this.data.showNameField && !this.data.showParentEmailField) {
        classes += 'flex-row';
      }
    }
    return classes;
  }

  ngOnInit(): void {
    this.setComponentData();
    this.setTheme();
    this.signUpForm = this.formBuilder.group({
      email: ['', [Validators.email, Validators.required]],
      name: ['', [Validators.pattern('^[a-zA-Z ]*$')]],
      phoneNumber: ['', [Validators.pattern('^[0-9]*$')]],
      parentEmail: ['', [Validators.email]],
    });

    this.formFields = this.getFormFields();
  }

  getFormFields(): { name: string; label: string; type: string; required: boolean }[] {
    const fields = [];

    if (this.fullForm) {
      if (this.data.showNameField) {
        fields.push({ name: 'name', label: 'Name', type: 'text', required: true });
      }
      if (!this.data.hideEmailField) {
        fields.push({ name: 'email', label: 'Email Address', type: 'email', required: true });
      }
      if (this.data.showParentEmailField) {
        fields.push({ name: 'parentEmail', label: "Parent's Email (Optional)", type: 'email', required: false });
      }
      if (this.data.showPhoneNumberField) {
        fields.push({ name: 'phoneNumber', label: 'Phone Number', type: 'tel', required: false });
      }
    } else {
      if (!this.data.hideEmailField) {
        fields.push({ name: 'email', label: 'Email Address', type: 'email', required: true });
      }
      if (this.data.showPhoneNumberField) {
        fields.push({ name: 'phoneNumber', label: 'Phone Number', type: 'tel', required: false });
      }
      if (this.data.showParentEmailField) {
        fields.push({ name: 'parentEmail', label: "Parent's Email (Optional)", type: 'email', required: false });
      }
    }

    return fields;
  }

  hasError(fieldName: string): boolean {
    const control = this.signUpForm?.get(fieldName);
    return !!(control && control.errors && control.touched);
  }

  getErrorMessage(fieldName: string): string {
    if (this.hasError(fieldName)) {
      return `Please enter a valid ${fieldName.replace(/([A-Z])/g, ' $1').toLowerCase()}${
        fieldName.toLowerCase().includes('email') ? ' address' : ''
      }`.trim();
    }
    return '';
  }

  getProduct(): void {
    const product = this.ps.extractProduct(this.data.product);
    this.ps.getProductData(product).then((product) => {
      this.product = product;
    });
  }

  getFormPayload(listId: number): FormPayload {
    const userType = this.getUserType(listId);
    const productCategoryHandle = this.env.get.brandConfig.productCategoryHandle;
    if (this.data.formVertical === 'RE') {
      return {
        listId: listId,
        subscribers: [
          {
            dataFields: {
              ...(this.product && { courseState: this.product.craftData.productState }),
              ...(this.product && { courseType: this.product.apiData.filterType }),
              ...(this.product && {
                productType:
                  this.product.craftData[productCategoryHandle].length > 0
                    ? this.product.craftData[productCategoryHandle][0].aceCategoryCode
                    : '',
              }),
              brandId: this.env.get.brandConfig.id,
              studentFirstName: this.name.value ? this.name.value : '',
              studentEmail: this.email.value ? this.email.value : '',
              parentEmail: this.parentEmail.value ? this.parentEmail.value : '',
              phoneNumber: this.phoneNumber.value ? this.phoneNumber.value : '',
            },
            email: this.data.hideEmailField
              ? `${this.phoneNumber.value}@placeholder.email`
              : userType === 'student' && this.email.value
              ? this.email.value
              : this.parentEmail.value,
          },
        ],
        type: this.data.formVertical,
      };
    }
    return {
      listId: listId,
      subscribers: [
        {
          dataFields: {
            brandId: this.env.get.brandConfig.id,
            studentFirstName: this.name.value ? this.name.value : '',
            studentEmail: this.email.value ? this.email.value : '',
            parentEmail: this.parentEmail.value ? this.parentEmail.value : '',
            phoneNumber: this.phoneNumber.value ? this.phoneNumber.value : '',
            courseState: this.product ? this.product.craftData.productState : '',
            courseType: this.product ? this.product.apiData.filterType : '',
            userType: userType,
          },
          email: this.data.hideEmailField
            ? `${this.phoneNumber.value}@placeholder.email`
            : userType === 'student' && this.email.value
            ? this.email.value
            : this.parentEmail.value,
        },
      ],
      type: this.data.formVertical,
    };
  }

  getUserType(listId: number): string {
    const { showParentEmailField, formVertical } = this.data;
    const isDE = formVertical === 'DE' || formVertical === 'INS' || formVertical === 'MTG';
    const isRE = formVertical === 'RE';
    const idArray = this.idArray;

    if (isDE) {
      if (!showParentEmailField || idArray.indexOf(listId) > 0) {
        return UserType.Student;
      }
      return UserType.Parent;
    }

    if (isRE) {
      return showParentEmailField ? UserType.Parent : UserType.Student;
    }

    return UserType.Unknown;
  }

  /**
   * Checks if user exists, to determine if courseState should be updated
   * @param {listId} number list id to get form payload
   */
  async iterableSubscribe(listId: number): Promise<void> {
    const subscribeData = this.getFormPayload(listId);
    subscribeData.subscribers.forEach((subscriber) => {
      const { dataFields } = subscriber;
      // If optional parent email is not provided - set userType to student
      if (dataFields.userType === 'parent' && !dataFields.parentEmail) {
        dataFields.userType = 'student';
        subscriber.email = dataFields.studentEmail;
      }
    });
    await this.iterable
      .addToMailList(subscribeData, this.env.get.brandConfig.id)
      .catch((error) => {
        this.postErr = true;
      })
      .then((response) => {
        if (!this.postErr) {
          this.formSubmitted = true;
        }
      });
  }

  setComponentData(): void {
    this.hasContent = this.data.formContent && this.data.formContent.length > 0;
    this.formContent = this.redactor.bypassSanitizer(this.data.formContent);
    this.ctaLayout = this.data.ctaType;
    if (this.data.formVertical === 'RE') {
      this.reListIds = this.data.subscriptionListIds
        ? this.data.subscriptionListIds.split(',').map((item) => parseInt(item))
        : [92256];
    } else {
      this.deListIds = this.data.subscriptionListIds
        ? this.data.subscriptionListIds.split(',').map((item) => parseInt(item))
        : [916471, 916472];
      if (!this.data.showParentEmailField && this.deListIds[0] === 916471) {
        this.deListIds.shift();
      }
    }
  }

  setTheme(): void {
    const themeInput: ComponentTheme = {
      styles: this.data.styles,
      backgroundColor: this.data.backgroundColor,
      backgroundImage: this.data.backgroundImage?.[0],
      textColor: this.data.textColor,
      columnCount: 0,
      columnDistribution: '',
    };
    this.theme = this.themeParser.getThemeClasses(themeInput);
    if (this.ps.isProductValid(this.data.product)) {
      this.getProduct();
    } else {
      this.ps.currentData.pipe(takeWhile((x) => x === null || Object.keys(x).length > 1)).subscribe((data): void => {
        if (data) {
          this.product = data;
        }
      });
    }
  }

  onSubmit(): void {
    this.postErr = false;
    if (this.signUpForm.valid) {
      this.formErr = false;
      const idArray = this.idArray;
      idArray.forEach((id: number) => {
        this.iterableSubscribe(id);
      });
    } else if (this.data.hideEmailField) {
      this.formErr = false;
      const idArray = this.idArray;
      idArray.forEach((id: number) => {
        this.iterableSubscribe(id);
      });
    } else {
      this.formErr = true;
    }
  }

  getAlt(): string {
    const altText = 'A product image';

    return this.data.emailFormImage && this.data.emailFormImage.length > 0 && this.data.emailFormImage[0].altText
      ? this.data.emailFormImage[0].altText
      : altText;
  }
}
