import type { Footer } from './footer';

export const footerData: Footer = {
  aceFooterLogo: [
    {
      typeHandle: 'logo',
      logoLink: 'https://www.mortgage.aceable.com/',
      logoImage: [
        {
          url: 'https://xgrit-ecom.imgix.net/v3_images//transforms/_350x89_crop_center-center_none/ace_mtg_logo.svg?v=1730836210&auto=compress,format',
          altText: 'Aceable Mortgage logo',
        },
      ],
    },
  ],
  aceGeneralLinkGroup: [
    {
      typeHandle: 'link',
      linkUrl: 'https://mortgage.aceable.com/support/',
      linkText: 'Help Center',
      newTab: false,
      nofollow: false,
    },
    {
      typeHandle: 'link',
      linkUrl: 'https://mortgage.aceable.com/resources/',
      linkText: 'Blog',
      newTab: false,
      nofollow: false,
    },
  ],
  aceVerticalLinkGroup: [
    {
      typeHandle: 'link',
      linkUrl: 'https://www.aceable.com/driving/',
      linkText: 'Driving',
      newTab: false,
      nofollow: false,
    },
    {
      typeHandle: 'link',
      linkUrl: 'https://www.aceableagent.com/',
      linkText: 'Real Estate',
      newTab: false,
      nofollow: false,
    },
    {
      typeHandle: 'link',
      linkUrl: 'https://insurance.aceable.com/',
      linkText: 'Insurance',
      newTab: false,
      nofollow: false,
    },
    {
      typeHandle: 'link',
      linkUrl: 'https://mortgage.aceable.com/',
      linkText: 'Mortgage',
      newTab: false,
      nofollow: false,
    },
  ],
  aceSocialLinkGroup: [
    {
      typeHandle: 'socialLink',
      linkUrl: 'https://www.instagram.com/aceablemortgage/',
      linkText: 'Instagram',
      newTab: true,
      nofollow: false,
      icon: 'instagram',
    },
    {
      typeHandle: 'socialLink',
      linkUrl: 'https://www.tiktok.com/@aceablemortgage',
      linkText: 'TikTok',
      newTab: true,
      nofollow: false,
      icon: 'tiktok',
    },
    {
      typeHandle: 'socialLink',
      linkUrl: 'https://www.facebook.com/profile.php?id=61570449813210',
      linkText: 'Facebook',
      newTab: true,
      nofollow: false,
      icon: 'facebook-square',
    },
  ],
  bottomLinkGroup: [
    {
      typeHandle: 'link',
      linkText: 'Privacy Policy',
      linkUrl: 'https://mortgage.aceable.com/privacy-policy/',
      nofollow: false,
      newTab: false,
    },
    {
      typeHandle: 'link',
      linkText: 'Terms & Conditions',
      linkUrl: 'https://mortgage.aceable.com/terms-and-conditions/',
      nofollow: false,
      newTab: false,
    },
  ],
  aceShareGroup: [
    {
      label: 'Partner with Us',
      link: '/driving-partnerships/',
    },
    {
      label: 'Refer a Friend',
      link: '/referral/invite/',
    },
  ],
  aceShowHideFooterNav: true,
  emailCapture: [],
};
