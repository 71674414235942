export const footerNavigation = [
  {
    headlineText: 'Customer Support',
  },
  {
    text: 'Help Center',
    subLink: '/support/',
    nofollow: false,
  },
  {
    text: 'Contact Us',
    subLink: '/support/?a=Contact-Info-and-Refunds---id--IRNX4b4ARziackisdImZ9Q',
    nofollow: false,
  },
  {
    text: 'Privacy Policy',
    subLink: '/about-us/privacy-policy/',
    nofollow: false,
  },
  {
    text: 'Terms and Conditions',
    subLink: '/terms-and-conditions/',
    nofollow: false,
  },
  {
    headlineText: 'Courses',
  },
  {
    text: 'Teen Drivers Ed',
    subLink: '/teen-drivers-ed/',
    nofollow: false,
  },
  {
    text: 'In-Car Driving Lessons',
    subLink: '/driving-lessons/',
    nofollow: false,
  },
  {
    text: 'Adult Drivers Ed',
    subLink: '/adult-drivers-ed/',
    nofollow: false,
  },
  {
    text: 'Traffic School/Defensive Driving',
    subLink: '/traffic-school/',
    nofollow: false,
  },
  {
    headlineText: 'Driver Resources',
  },
  {
    text: 'Blog',
    subLink: '/trending/',
    nofollow: false,
  },
  {
    text: 'Driving Glossary: Useful Driving Terms',
    subLink: '/resources/terms/',
    nofollow: false,
  },
  {
    text: 'DMV Information',
    subLink: '/dmv/',
    nofollow: false,
  },
  {
    text: 'Auto Central',
    subLink: '/resources/auto-central/',
    nofollow: false,
  },
  {
    text: 'Driving Information',
    subLink: '/driving-information/',
    nofollow: false,
  },
  {
    headlineText: 'Become a Partner',
    headlineLink: '/affiliates/',
  },
  {
    headlineText: 'Accessibility Statement',
    headlineLink: '/accessibility-statement/',
  },
];
